//用户名匹配
export function nameRule(rule, value, callback) {
    // 请输入4~16位用户名
    let reg = /^[A-Za-z0-9_@./#&+-]{0,50}$/
    if (value === '') {
        callback(new Error('请输入用户名'))
    } else if (!reg.test(value)) {
        callback(new Error('用户名不符合要求，可以包含字母、数字和指定的特殊字符，且长度在3到50个字符之间'))
    } else {
        callback()
    }
}

//密码匹配
export function passRule(rule, value, callback) {
    // 请输入6~18位用户名
    let pass = /^(?![a-zA-Z]+$)(?!\d+$)(?![^\da-zA-Z\s]+$).{6,48}$/
    if (value === '') {
        callback(new Error('请输入密码'))
    } else if (!pass.test(value)) {
        callback(new Error('密码不符合要求，长度在6到48个字符之间，并且至少包含字母、数字和符号中的两种。'))
    } else {
        callback()
    }
}