<template>
    <div style="
    background-image: url('https://imgapi.xl0408.top/index.php'); 
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    ">
        <el-row>
            <el-col :xs="24" :sm="10" style="background-color: rgba(255, 255, 255, 0.9); height: 100vh;">
                <div class="center-content">
                    <el-avatar :size="100" src="https://chmlfrp.cn/favicon.ico"></el-avatar>
                    <div class="masked">
                        <h1>Login to ChmlFrp</h1>
                    </div>
                    <el-form :model="form" ref="form" :rules="rules" class="custom-form">
                        <el-form-item label="用户名/邮箱/QQ号" prop="username">
                            <el-input v-model="form.username"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input :type="passwordFieldType" v-model="form.password" clearable>
                                <template v-slot:append>
                                    <el-button :icon="showPassword ? 'el-icon-close' : 'el-icon-view'"
                                        @click="togglePasswordVisibility"></el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-checkbox v-model="checked">自动登录</el-checkbox>
                            <el-link :underline="false" style="float: right;" href="/reset">忘记密码?</el-link>
                        </el-form-item>
                        <el-button type="primary" style="width: 100%;" v-loading="loading"
                            @click="login('form')">登录</el-button>
                    </el-form>
                    <el-link style="top: 20px;" :underline="false" href="/register">尚未注册账户？点此注册！</el-link>
                    <p style="position: fixed; bottom: 0; width: 100%; text-align: center; font-size: 14px;">
                        Copyright © 2021 - 2025 ChmlFrp All rights reserved.
                    </p>
                </div>
            </el-col>
            <el-col :xs="24" :sm="8"></el-col>
        </el-row>
    </div>
</template>

<script>
import { nameRule, passRule } from '../utils/vaildate.js'
import { setToken } from '../utils/setToken.js'

export default {
    data() {
        return {
            loading: false,
            checked: true,
            form: {
                username: '',
                password: '',
            },
            rules: {
                username: [{ validator: nameRule, trigger: 'blur' }],
                password: [{ validator: passRule, trigger: 'blur' }]
            },
            showPassword: false,
        };
    },
    computed: {
        passwordFieldType() {
            return this.showPassword ? 'text' : 'password';
        },
    },
    mounted() {
        this.$router.push('/home/one')
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },

        login(form) {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (
                        !this.form.username ||
                        !this.form.password
                    ) {
                        this.$message({
                            message: "请填写完整的登录信息",
                            type: "warning",
                        });
                        this.loading = false;
                        return;
                    }
                    this.$refs[form].validate(async (valid) => {
                        if (valid) {
                            console.log(this.form);
                            // 构建要发送的数据对象
                            const data = new FormData();
                            data.append('username', this.form.username);
                            data.append('password', this.form.password);
                            this.axios.post('https://cf-v1.uapis.cn/api/login.php', data)
                                .then(async res => {
                                    console.log(res.data);
                                    if (res.data.code === 200) {
                                        setToken('userid', res.data.userid)
                                        setToken('username', res.data.username)
                                        setToken('userimg', res.data.userimg)
                                        setToken('token', res.data.token)
                                        setToken('email', res.data.email)
                                        setToken('tunnel', res.data.tunnel)
                                        setToken('usergroup', res.data.usergroup)
                                        setToken('qq', res.data.qq)
                                        setToken('bandwidth', res.data.bandwidth)
                                        setToken('realname', res.data.realname)
                                        setToken('integral', res.data.integral)
                                        setToken('term', res.data.term)
                                        setToken('background_img', res.data.background_img)
                                        setToken('Element_transparency', res.data.Element_transparency)
                                        this.$message({ message: res.data.message, type: 'success' })
                                        this.$router.push('/home/one')
                                    } else {
                                        this.$message({ message: res.data.error, type: 'error' })
                                    }
                                })
                                .catch(error => {
                                    console.error(error);
                                })
                                .finally(() => {
                                    this.loading = false; //结束确认按钮加载状态
                                })
                        } else {
                            console.error(this.form);
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="less">
.center-content {
    display: flex;
    flex-direction: column;
    /* 垂直居中时，垂直排列子元素 */
    justify-content: center;
    /* 垂直居中 */
    align-items: center;
    /* 水平居中 */
    height: 70%;
    /* 使其占据整个父元素的高度 */
}

.custom-form {
    width: 100%;
    max-width: 360px;
    /* 设置最大宽度为360px */
}

.masked {
    display: block;
    text-align: center;
    width: 100%;
    height: 80px;
    background-image: linear-gradient(to right, #3498db, #f47920 10%, #d71345 20%, #f7acbc 30%, #ffd400 40%, #3498db 50%, #f47920 60%, #d71345 70%, #f7acbc 80%, #ffd400 90%, #3498db);
    background-image: -webkit-linear-gradient(left, #3498db, #f47920 10%, #d71345 20%, #f7acbc 30%, #ffd400 40%, #3498db 50%, #f47920 60%, #d71345 70%, #f7acbc 80%, #ffd400 90%, #3498db);
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-size: 200% 100%;

    animation: masked-animation 6s infinite linear;
}

@keyframes masked-animation {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -100% 0;
    }
}
</style>